<template>
  <div class="flex flex1 height_100p">
    <Sidebar  @searchList="searchList" ></Sidebar>
    <el-row class="flex flexcolumn flex1">
      <div class="fontsize_14 color_222" style="min-width: 552px;">
        <el-select
          class="photo-gallery-select noborder"
          v-model="orderBy"
          placeholder=""
          @change="changeOrder"
          popper-class="list-order-tool"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in orderList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <vue-waterfall-easy :imgsArr="list" @scrollReachBottom="loadData" @click="tapPhoto" :gap="16" :imgWidth="380" class="flex1 prel" >
        <div class="img-mask" slot-scope="props" @click.stop="tapPhoto(props.index, props.value)">
          <div class="img-tips fontsize_14 color_fff">点击图片上传至媒体库</div>
          <div class="img-action flex flex_acenter"  @click.stop>
            <div class="img-action-item cursorDefault  textcenter flex flex_acenter">
              <i class="fontsize_18" :class="{'icon-loving_heart_selected': true }" style="color:#d64d2f"></i><span class="padding_left8 fontsize_12">{{props.value.likes}}</span>
            </div>
            <a :href="props.value.links.html" target="_blank" class="img-action-item fontsize_18 margin_left8 textcenter notunderline"><i class="icon-link_nav_actived"></i></a>
          </div>
          <div class="img-info flex flex_acenter cursorDefault" @click.stop>
            <img v-if="props.value && props.value.src" :src="props.value.src" alt="" class="img-info-img" loading="lazy">
            <p class="some-info fontsize_14 color_fff padding_left8 pover" style="width:160px">{{props.value.user.name}}</p>
            <i class="icon-photo_settings fontsize_18 color_fff img-info-icon pointer" @click="uploadWithInfo(props.value)"></i>
          </div>
        </div>
        <div slot="loading" slot-scope="e">
          <div slot="loading" v-if="list.length > 0">loading...</div>
          <div v-else>暂无相关图片数据</div>
        </div>
      </vue-waterfall-easy>
      <fd-dialog
        :title="dialogSet.title"
        :visible.sync="editVisible"
        width="1036px"
        :before-close="(arg)=>beforeCloseDialog(arg,'edit')"
        custom-class="course-dialog full">
        <el-form @submit.native.prevent>
          <div v-for="ditem in dialogSet.fieldList" :key="ditem.id" class="dialog-form-item">
            <p class="margin_bot8 fontsize_14 color_222"><span>{{ditem.label}}</span><span class="padding_left4 color_D0021B" v-if="ditem.type === 'uploadFile' || !!ditem.required">*</span></p>
            <el-input v-if="ditem.type === 'text'"
              v-model="uploadParams[ditem.propsName]" autocomplete="off" :placeholder="ditem.placeholder" class="media-edit-input noborder"></el-input>
            <div v-else-if="ditem.type === 'selectAllowCreate'" class="prel">
              <el-select 
                v-model="uploadParams[ditem.propsName]"
                value-key="tag_name"
                multiple
                filterable
                remote
                allow-create
                default-first-option
                :placeholder="ditem.placeholder"
                :remote-method="remoteTags"
                @change="changeSelectTag($event,ditem.propsName)"
                class="select-allow-create noborder width_100p photo-select-tags">
                <el-option
                  v-for="item in fileTagList"
                  :key="item.id"
                  :label="item.tag_name"
                  :value="item.tag_name">
                </el-option>
              </el-select>
              <span  v-if="oldTag.length" class="clear-tags pointer el-select__caret el-input__icon el-icon-circle-close" @click.stop="clearTags"></span>
            </div>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer" style="padding-top:26px;">
          <button @click="saveMedia" class="media-upload pointer">上传媒体库</button>
          <button @click="closeDialogEdit" class="course-close pointer">取消</button>
        </div>
      </fd-dialog>
    </el-row>
  </div>
</template>

<script>
import Config from '@/libs/config';
import { debounce } from '@/libs/utils/tools';
import vueWaterfallEasy from 'vue-waterfall-easy'
import { Sidebar } from '@/renderer/components/Dashboard/components';
import { createApi } from 'unsplash-js';
import { mapActions } from 'vuex';
import { updateMediaFile, addMediaTag, uploadWebImage } from '@/libs/api/media';
const unsplash = createApi({ accessKey: 'q6M9T-JBtfHGKd2GM3Q8FimFrG5mKz33keupu6mErnI', });
export default {
  components: {
    Sidebar,
    vueWaterfallEasy
  },
  data() {
    return {
      page:1,
      total:-2,
      limit:20,
      keyword:"",
      orderBy: "latest", //列表排序
      list: [],
      lang:"zh",
      orderList: [
        { label: "最新上传", value: "latest" },
        { label: "最早上传", value: "oldest" },
        { label: "最受欢迎", value: "popular" },
      ],
      editVisible: false, //编辑弹窗
      dialogSet: { 
        title:"编辑图片",
        actionType:"edit", //edit add
        fieldList: [
          {
            id: 202,
            type: "text",
            label:"文件名称",
            placeholder: "请输入文件名称",
            propsName:"name",
            required:true
          },
          {
            id: 203,
            type: "text",
            label:"文件描述",
            placeholder: "请输入文件描述",
            propsName:"description"
          },
          {
            id: 204,
            type: "selectAllowCreate",
            label:"文件标签",
            placeholder: "请输入标签，回车建立标签",
            propsName:"tag_name_list"
          }
        ],
        item:{}
      },
      uploadParams: {
        name:"",
        description:"",
        tag_name_list:[], //标签数组
      },
      oldTag:[], //标签选中
      tagList:[],
      fileTagList:[],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    ...mapActions(['GetMediaTagList']),
    loadData() {
      // 加载下一页
      if(this.page * this.limit >= this.total) return;
      this.page++;
      if(this.keyword != "") {
        this.getListOfKeyword();
      }else {
        this.getList();
      }
    },
    getList() {
      unsplash.photos.list(
        {
          perPage: this.limit,
          page: this.page,
          orderBy: this.orderBy
        },
      ).then(result => {
        if (result.errors) {
          // handle error here
          console.log('error occurred: ', result.errors[0]);
        } else {
          // handle success here
          let photoRes = result.response;
          
          this.total = photoRes.total;
          photoRes.results.forEach(v => {
            v.src = v.urls.small
          })
          this.list = this.page == 1 ? photoRes.results : this.list.concat(photoRes.results);
        }
      });
    },
    getListOfKeyword() {
      // 搜索
      unsplash.search.getPhotos(
        {
          query: this.keyword,
          perPage: this.limit,
          page: this.page,
          orderBy: this.orderBy,
          lang: this.lang
        },
      ).then(result => {
        if (result.errors) {
          // handle error here
          console.log('error occurred: ', result.errors[0]);
        } else {
          // handle success here
          let photoRes = result.response;
          
          this.total = photoRes.total;
          photoRes.results.forEach(v => {
            v.src = v.urls.small
          })
          this.list = this.page == 1 ? photoRes.results : this.list.concat(photoRes.results);
        }
      });
    },
    changeOrder(val) {
      // 排序方式
      this.list=[];
      this.page=1;
      this.orderBy = val;
      this.getList();
    },
    searchList: debounce(
      function({keyword}){
        // 通过关键词搜索
        if(this.keyword === keyword) return;
        this.keyword = keyword;
        this.page=1;
        this.list=[];
        this.getListOfKeyword();
      },
      Config.sidebarSearchDebounceDuration, true
    ),
    tapPhoto(index, value ) {
      // 直接上传媒体库
      this.uploadParams.description = value.description;
      this.dialogSet.item = value;
      this.saveMedia();
    },
    clickFn(event, { index, value }) {
      // Prevent a tag jump
      event.preventDefault()
      // Do it only when you click on the image
      if (event.target.tagName.toLowerCase() == 'img') {
        
      }
    },
    uploadWithInfo(item) {
      // 弹窗-上传媒体库
      this.editVisible = true;
      this.uploadParams.description = item.description;
      this.dialogSet.item = item;
    },
    beforeCloseDialog(done, actionType) { 
      //弹窗关闭前 回调
      done();
      // 编辑弹窗
      if(actionType === 'edit') {
        for(let key in this.uploadParams) {
          this.uploadParams[key] = "";
        }

        this.editVisible = false;
        return;
      }
    },
    closeDialogEdit() {
      //关闭 编辑弹窗
      this.editVisible = false;
      Object.keys(this.uploadParams).forEach(v => {
        this.uploadParams[v] = "";
      });
    },
    saveMedia() {
      // 编辑/上传 媒体文件
      let type = this.dialogSet.actionType;
      
      let { name, description, tag_name_list } = this.uploadParams;
      uploadWebImage({
        image_url: this.dialogSet.item.urls.full, 
        name: name, 
        description: description, 
        tag_name_list: tag_name_list
      }).then(res => {
        if(res.errorcode == 0) {
          // 关闭弹窗
          this.$message.success("上传图片成功！");
          if(this.editVisible) this.closeDialogEdit();
        }
      });
    },
    remoteTags(query) {
      // 远程搜索标签
      if(query === '') {
        this.fileTagList = this.tagList;
        return;
      }
      this.GetMediaTagList(query).then(res => {
        if(res.errorcode == 0) {
          this.fileTagList = res.data.data;
          this.$forceUpdate();
        }
      }).catch();
    },
    changeSelectTag(val, prop) {
      // 选中值发生变化
      // console.log('选中值发生变化',val,prop ,this.oldTag);
      let newVal = this.uploadParams[prop].filter(v => {
        return !this.oldTag.includes(v)
      }) 
      // console.log('新增',newVal);
      this.oldTag.push(...newVal);
      newVal.forEach(v => {
        addMediaTag(v).then(res => {
          if(res.errorcode == 0) {
            // this.uploadParams.tag_name_list.push(res.data.tag_name);
          }
        })
      } )
    },
    clearTags() {
      // 清空标签
      this.uploadParams.tag_name_list = [];
      this.oldTag = [];
    },
  },
}
</script>

<style lang="less" scoped>
  @imgPadding: 8px;
  /deep/.vue-waterfall-easy-scroll .img-box {
    cursor: pointer;
    &:hover {
      .img-mask {
        background-image: linear-gradient(180deg, #000000 0%, rgba(0,0,0,0.60) 30%, rgba(0,0,0,0.60) 71%, #000000 100%);
      }
      .img-info {
        opacity: 1;
      }
      .img-action-item {
        opacity: 1;
      }
      .img-tips {
        opacity: 1;
      }
    }
  }
  .img-mask {
    position: absolute;
    top: @imgPadding;
    left: @imgPadding;
    right: @imgPadding;
    bottom: @imgPadding; //盒子padding值
  }
  .img-action {
    position: absolute;
    right: 16px;
    top: 16px;
    .img-action-item {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity .6s;
      opacity: 0;
      border-radius: 4px;
      padding: 0 8px;
      height: 32px;
      line-height: 32px;
      background: #FFFFFF;
    }
  }
  .img-tips {
    margin-top: 16px;
    padding-left: 16px;
    line-height: 32px;
    transition: opacity .6s;
    opacity: 0;
  }
  .img-info {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; //盒子padding值
    padding: 16px;
    // background: rgba(0,0,0, 0.5);
    opacity: 0;
    transition: opacity .6s;

    .img-info-icon {
      padding: 16px;
      position: absolute;
      right: 0;
    }
    .img-info-img {
      border-radius: 50%;
      border: 1px solid #FFFFFF;
      width: 30px;
      height: 30px;
    }
  }

  .photo-gallery-select {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 110px;
    height: 40px;
    line-height: 30px;
    /deep/.el-input__inner {
      padding-left: 0 !important;
    }
  }
  .photo-select-tags {
    min-height: 100px;
    height: 100px;
    max-height: 100px;
    /deep/.el-select__tags {
      top: 6px;
      left: 0px;
      transform: translateY(0);
    }
    /deep/.el-tag {
      margin-left: 14px;
    }
  }
</style>